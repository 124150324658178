
import { defineComponent, PropType, ref } from 'vue'
import { User, PageResponse, DelFlag } from 'momai'
import { FetchFunction, useLoadList } from '@/utils/useLoadList'
import { list } from '@/api/user'
import { fromNow } from '@/utils/format'
import { search as keyword } from '@/states/search'

export default defineComponent({
  props: {
    delFlag: {
      type: Number as PropType<DelFlag | undefined>
    },
    toFormatter: {
      type: Function as PropType<(id: number) => string>
    }
  },
  setup: (props) => {
    const students = ref<User[]>([])

    const totalNum = ref(-1)
    const fetch: FetchFunction = (pageData, loadEnd) => {
      list({
        username: keyword.value,
        pageData,
        delFlag: props.delFlag
      }).then(({
        records,
        total
      }: PageResponse<User>) => {
        totalNum.value = total
        students.value.push(...records)
        loadEnd()
      })
    }

    const { loadMore, finished, loading, reset } = useLoadList({
      fetch,
      total: totalNum,
      list: students
    })

    return {
      fromNow,
      reset,
      keyword,
      students,
      loadMore,
      finished,
      loading
    }
  }
})
